
import { defineComponent } from 'vue';
// import IconPath from '@/components/Icon/IconPath.vue';
import { numberToMoney } from '@/helpers/currency';
import IconDown from '@/components/Icon/IconDown.vue';
import IconUp from '@/components/Icon/IconUp.vue';
import { formatDate } from '@/helpers';

export default defineComponent({
  name: 'OverviewHistoryInvestmentPoolItem',
  components: {
    // IconPath,
    IconUp,
    IconDown,
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    classRate(): string {
      const rate = this.data?.investProfit;
      let name = '';
      if (rate >= 0) {
        name = 'green';
      } else {
        name = 'red';
      }
      return name;
    },
  },
  methods: {
    formatDate,
    numberToMoney,
  },
});
