
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import MarketItem from './OverviewHistoryInvestmentPoolItem.vue';
import constRouter from '@/constants/constRouter';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import InfiniteLoading from 'infinite-loading-vue3-ts';

export default defineComponent({
  name: 'OverviewHistoryInvestmentPool',
  components: {
    DefaultLayout,
    MarketItem,
    InfiniteLoading,
    SketetonLoader,
  },
  // setup() {
  //   const store = useStore();
  //   const data = store.getters.getListContract;
  //   if (data && data.length === 0) {
  //     store.dispatch('fetchListContract');
  //   }
  // },
  data() {
    return {
      loading: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      historyInvestment: 'getHistoryInvestment',
      currentHistoryInvest: 'getCurrentHistoryInvest',
    }),
  },
  methods: {
    ...mapActions({
      fetchListHistoryInvest: 'fetchListHistoryInvest',
    }),
    ...mapMutations({
      setHistoryInvestment: 'setHistoryInvestment',
    }),
    async infiniteHandler($state: any): Promise<void> {
      await this.fetchListHistoryInvest();
      if (this.currentHistoryInvest.length) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    handleClick(contractId: string) {
      this.$router.push({
        name: constRouter.OVERVIEW_PACEKAGE.name,
        params: { id: contractId },
      });
    },
  },
});
